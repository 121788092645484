import React from 'react';
import { graphql } from 'gatsby';
import { PageWrapper } from '../components/PageWrapper';
import { Hero } from '../components/Hero';

import { StructuredText } from 'react-datocms';

const ImpresspageTemplate = ({
    data: {
        datoCmsImpresspage: {
            seo,
            impressContent,        
        },
    },
    pageContext,
}) => (
    <PageWrapper
        pageData={pageContext}
        seoTitle={seo?.seoTitle}
        seoDescription={seo?.seoDescription}
        seoImage={seo?.seoImage?.seoImageUrl}
    >
    <div className='TermsNavBackground'></div>
    <div className='TermsWrapper'>
        <StructuredText data={impressContent} />
    </div>
        
    </PageWrapper>
);

export default ImpresspageTemplate;

export const query = graphql`
    query ImpresspageQuery($locale: String!) {
        datoCmsImpresspage(locale: { eq: $locale }) {
            locale
            seo {
                seoTitle: title
                seoDescription: description
                seoImage: image {
                    seoImageUrl: url
                }
            }
            impressContent {
                value
            }
        }
    }
`;